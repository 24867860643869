import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlunoForm = _resolveComponent("AlunoForm")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AlunoForm, {
      onUpdate: _ctx.list,
      ref: "modalForm"
    }, null, 8, ["onUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_table, {
        title: "Alunos",
        "row-key": "id",
        "rows-per-page-label": "Alunos por página",
        rows: _ctx.rows,
        columns: _ctx.columns,
        filter: _ctx.filter,
        loading: _ctx.loading,
        pagination: { rowsPerPage: 10 }
      }, {
        top: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_btn, {
              label: "Novo Aluno",
              color: "primary",
              icon: "add",
              onClick: _ctx.openModalForm
            }, null, 8, ["onClick"]),
            _createVNode(_component_q_toggle, {
              modelValue: _ctx.showActives,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showActives) = $event)),
              label: "Mostrar somente alunos ativos"
            }, null, 8, ["modelValue"])
          ]),
          _createVNode(_component_q_space),
          _createVNode(_component_q_btn, {
            color: "primary",
            "icon-right": "archive",
            label: "Exportar lista de alunos",
            "no-caps": "",
            onClick: _ctx.exportTable
          }, null, 8, ["onClick"]),
          _createVNode(_component_q_input, {
            dense: "",
            debounce: "300",
            color: "primary",
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "search" })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        "body-cell-imagem": _withCtx((props) => [
          _createVNode(_component_q_td, {
            props: props,
            style: {"width":"50px"}
          }, {
            default: _withCtx(() => [
              (props.value)
                ? (_openBlock(), _createBlock(_component_q_avatar, {
                    key: 0,
                    size: "40px"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: `${_ctx.urlImg}${props.value}`
                      }, null, 8, _hoisted_3)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createBlock(_component_q_avatar, {
                    key: 1,
                    size: "40px"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: require('../../../assets/aluno-avatar.png')
                      }, null, 8, _hoisted_4)
                    ]),
                    _: 1
                  }))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-biometria": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.value)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 0,
                    color: "green",
                    label: "CADASTRADA"
                  }))
                : (_openBlock(), _createBlock(_component_q_badge, {
                    key: 1,
                    color: "negative",
                    label: "NÃO CADASTRADA"
                  }))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-status": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.value)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 0,
                    color: "green",
                    label: "ATIVO"
                  }))
                : (_openBlock(), _createBlock(_component_q_badge, {
                    key: 1,
                    color: "negative",
                    label: "INATIVO"
                  }))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-created_at": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatData(props.value.toString())), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "primary",
                "icon-right": "edit",
                disable: _ctx.loading,
                onClick: ($event: any) => (_ctx.openModalForm(props.row))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Editar ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "primary",
                "icon-right": "link",
                onClick: ($event: any) => (_ctx.copyLink(props.row.id))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Copiar Código ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "green",
                "icon-right": "history_edu",
                to: '/notas-aluno/' + props.row.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Notas ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "filter", "loading"])
    ])
  ]))
}